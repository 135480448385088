<template>
  <b-sidebar id="sidebar-fuel-contact" :visible="isContactFuelProviderSidebarActive" @change="val => $emit('update:is-contact-sidebar-active', val)">
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          {{ $t('trip.fuel.sidebar.send_message_to') }} {{ providerFuel.organizationName }}
        </h5>

        <feather-icon class="mr-1 ml-auto cursor-pointer" icon="XIcon" size="16" @click="hide" />
      </div>
      <!-- RESUME -->

      <b-card-body>
        <!--SUBJECT-->
        <validation-observer ref="contactRules" tag="form">

          <b-form-group class="mt-1" required :label="$t('common.message')" label-for="serviceName">
            <validation-provider #default="{ errors }" rules="required" :name="$t('common.message')">
              <b-form-textarea id="serviceName" v-model="content" required rows="10" :state="errors.length > 0 ? false : null" />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <b-button type="submit" class="mt-1 mr-50 col-12" @click.prevent="validationForm">
            <font-awesome-icon icon="paper-plane" class="mr-50" />
            {{ $t('action.send') }}
          </b-button>

          <b-button variant="outline-secondary" class="mt-1 mr-50 col-12" @click="hide">
            <font-awesome-icon icon="ban" class="mr-50" />
            {{ $tc('action.cancel') }}
          </b-button>

        </validation-observer>
      </b-card-body>
    </template>
  </b-sidebar>
</template>
<script>

import { patchMessageChatRequest } from '@/request/globalApi/requests/chatRequests'

export default {
  name: 'SidebarContactFuelProvider',

  model: {
    prop: 'isContactFuelProviderSidebarActive',
    event: 'update:is-contact-sidebar-active',
  },
  props: {
    isContactFuelProviderSidebarActive: {
      type: Boolean,
      required: true,
    },
    providerFuel: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      content: '',
    }
  },
  methods: {
    validationForm() {
      this.$refs.contactRules.validate().then(success => {
        if (success) {
          patchMessageChatRequest(this.content, this.providerFuel.organizationId).then(() => {
            this.$emit('update:is-contact-sidebar-active', false)
            this.content = ''
          })
        }
      })
    },
  },
}
</script>
