import { snakeCase } from 'lodash'

export default {
  methods: {
    enumTranslate(enumClass, enumValue, snakeCaseText = false) {
      if (this.$te(`enum.${snakeCase(enumClass)}.${!snakeCaseText ? enumValue : snakeCase(enumValue)}`)) {
        return this.$t(`enum.${snakeCase(enumClass)}.${!snakeCaseText ? enumValue : snakeCase(enumValue)}`)
      }

      return enumValue
    },
  },
}
