<template>
  <b-card>
    <b-table
      ref="refFuelProviderTable"
      :empty-text="$t('common.no_record_found')"
      :fields="tableColumns"
      :items="providerList"
      :sort-by.sync="sortBy"
      class="position-relative"
      primary-key="id"
      responsive
      show-empty
    >
      <template #head(status)>
        <feather-icon class="mx-auto" icon="TrendingUpIcon" />
      </template>

      <template #head(basePrice)>
        {{ $t('trip.fuel.base_price_et') }}
      </template>

      <!-- Column: Seller -->
      <template #cell(seller)="data">
        <b-media vertical-align="center">
          <span class="font-weight-bold d-block text-nowrap">
            {{ data.item.organizationName }}
          </span>
          <small class="text-muted">{{ data.item.flightType }}</small>
        </b-media>
      </template>

      <!-- Column: Supplier -->
      <template #cell(supplier)="data">
        <b-media vertical-align="center">
          <span class="font-weight-bold d-block text-nowrap">
            {{ data.item.supplier }}
          </span>
        </b-media>
      </template>

      <!-- Column: Contract -->
      <template #cell(contract)="data">
        <b-badge :variant="`light-${resolveStatusVariant(data.item.contract)}`" class="mx-1">
          {{ data.item.contract ? $t('trip.fuel.contract') : $t('trip.fuel.no_contract') }}
        </b-badge>
      </template>

      <!-- Column: Base Price -->
      <template #cell(basePrice)="data">
        <span class="text-nowrap">
          {{ $t('trip.fuel.base_price_et') }} :
          <span v-if="data.item.unitBasePrice"> {{ data.item.unitBasePrice }} {{ $getMeasurementUnitItn('epl') }}</span>
          <span v-else-if="!data.item.public"> {{ $t('service.fuel.prices.private_price') }} </span>
          <span v-else> {{ $t('service.on_quote') }} </span>
        </span>
      </template>

      <!-- Column: Fuel type -->
      <template #cell(fuelType)="data">
        <span class="text-nowrap">
          <span v-if="data.item.fuelType"> {{ data.item.fuelType }} </span>
        </span>
      </template>

      <!-- Column: Actions -->
      <template #cell(actions)="data">
        <b-button
          class="mr-1"
          @click="
            isContactFuelProviderSidebarActive = true
            providerSelected = data.item
          "
        >
          <font-awesome-icon class="mr-50" icon="comment-dots" />
          {{ $t('action.contact') }}
        </b-button>
        <b-badge
          v-if="false && eventData.providers.some(provider => provider.provider.id === data.item.id && data.item.status === 'Pending')"
          variant="light-warning"
        >
          {{ $t('timeline.fuel.request_quotation.sent') }}
        </b-badge>
        <b-button
          v-else
          @click="
            isRequestQuotationSidebarActive = true
            providerSelected = data.item
            fuelTypeName = data.item.fuelType
          "
        >
          <font-awesome-icon class="mr-50" icon="file-invoice-dollar" />
          {{ $t('trip.fuel.request_quotation') }}
        </b-button>
      </template>
    </b-table>
    <div class="mx-2 mb-2">
      <b-row>
        <b-col class="d-flex align-items-center justify-content-center justify-content-sm-start" cols="12" sm="6">
          <span class="text-muted">
            {{ $t('pagination.showing_entries') }} {{ dataMeta.from }} {{ $t('pagination.entries_to') }} {{ dataMeta.to }} {{ $t('pagination.entries_of') }}
            {{ dataMeta.of }} {{ $tc('pagination.entries', totalItems) }}
          </span>
        </b-col>

        <!-- Pagination -->
        <b-col class="d-flex align-items-center justify-content-center justify-content-sm-end" cols="12" sm="6">
          <b-pagination
            v-model="params.page"
            :per-page="params.numberOfItemsPerPage"
            :total-rows="totalItems"
            class="mb-0 mt-1 mt-sm-0"
            first-number
            last-number
            next-class="next-item"
            prev-class="prev-item"
          >
            <template #prev-text>
              <feather-icon icon="ChevronLeftIcon" size="18" />
            </template>
            <template #next-text>
              <feather-icon icon="ChevronRightIcon" size="18" />
            </template>
          </b-pagination>
        </b-col>
      </b-row>
    </div>
    <sidebar-request-quotation v-model="isRequestQuotationSidebarActive" :fuel-settings="fuelSettings" :provider-fuel="providerSelected" :trip-data="tripData" :trip-step="tripStep" :trip-step-event="tripStepEvent" />
    <sidebar-contact-fuel-provider v-model="isContactFuelProviderSidebarActive" :provider-fuel="providerSelected" />
  </b-card>
</template>

<script>
import UIHelper from '@/helpers/ui'
import SidebarRequestQuotation from '@/views/provider/fuel/sidebar/SidebarRequestQuotation.vue'
import SidebarContactFuelProvider from '@/views/provider/fuel/sidebar/SidebarContactFuelProvider.vue'

export default {
  name: 'FuelProvider',

  components: {
    SidebarContactFuelProvider,
    SidebarRequestQuotation,
  },
  mixins: [UIHelper],
  props: {
    tripData: {
      type: Object,
      default: () => ({}),
    },
    tripStep: {
      type: Object,
      default: () => ({}),
    },
    tripStepEvent: {
      type: Object,
      default: () => ({}),
    },
    providerList: {
      type: Array,
      default: () => [],
    },
    fuelTypeName: {
      type: String,
      default: null,
    },
    fuelVolume: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      fuelMeasurementUnity: 'litres',
      measurementOptions: [{ label: 'Liters', value: 'litres' }],
      isRequestQuotationSidebarActive: false,
      isContactFuelProviderSidebarActive: false,
      providerSelected: null,

      tableColumns: [
        { key: 'seller' },
        { key: 'supplier' },
        { key: 'contract' },
        { key: 'basePrice' },
        { key: 'fuelType' },
        { key: 'actions' },
      ],
      statusOptions: ['Completed', 'In progress', 'Action required'],
      products: [],
      loadProducts: false,
      itemView: 'grid-view',
      itemViewOptions: [
        { icon: 'GridIcon', value: 'grid-view' },
        { icon: 'ListIcon', value: 'list-view' },
      ],
      params: {
        // PAGE
        numberOfItemsPerPage: 10,
        page: 1,
        // SORTING
        nameOrder: null,
        // SEARCH
        searchQuery: null,
        categoriesIds: null,
      },
      perPageOptions: [5, 10, 25, 50, 100],
      sortBy: null,
      sortDesc: null,
      totalItems: null,
    }
  },
  computed: {
    userLang() {
      return this.$store.state.appConfig.lang
    },
    fuelSettings() {
      return { fuelTypeName: this.fuelTypeName, quantity: this.fuelVolume, message: '', dateFuelRequested: this.tripStepEvent ? this.tripStepEvent.eventDateTime : null }
    },
    dataMeta() {
      const localItemsCount = this.products.length
      return {
        from: this.params.numberOfItemsPerPage * (this.params.page - 1) + (localItemsCount ? 1 : 0),
        to: this.params.numberOfItemsPerPage * (this.params.page - 1) + localItemsCount,
        of: this.totalItems,
      }
    },
    allFilters() {
      return {
        numberOfItemsPerPage: this.params.numberOfItemsPerPage,
        page: this.params.page,
        // SORTING
        nameOrder: this.params.nameOrder,
        // SEARCH
        searchQuery: this.params.searchQuery,
        categoriesIds: this.params.categoriesIds,
      }
    },
  },
  watch: {
    allFilters: {
      handler() {
        this.APIFetchProducts()
      },
      deep: true,
    },
    fuelSettings() {
      // ! Never used !?
      this.$emit('fuel-settings', this.fuelSettings)
    },
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.trip-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}
</style>
